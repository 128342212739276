import { Icon } from "@chakra-ui/react";

type Props = {
  fill?: string;
};

function FacebookIcon({ fill = "secondary.600" }: Props) {
  return (
    <Icon
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 100C22.4333 100 0 77.5667 0 50C0 22.4333 22.4333 0 50 0C77.5667 0 100 22.4333 100 50C100 77.5667 77.5667 100 50 100ZM50 6.66667C26.1 6.66667 6.66667 26.1 6.66667 50C6.66667 73.9 26.1 93.3333 50 93.3333C73.9 93.3333 93.3333 73.9 93.3333 50C93.3333 26.1 73.9 6.66667 50 6.66667Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.8333 73.3333V52.0667H58.7333L59.7667 43.7333H51.8333V38.4333C51.8333 36.0333 52.4667 34.4 55.8 34.4H60V26.9667C57.9667 26.7333 55.9 26.6333 53.8333 26.6333C48.4 26.3333 43.7667 30.5 43.5 35.9333C43.5 36.5 43.5 37.0333 43.5667 37.6V43.7H36.7V52H43.5667V73.2667H51.8333V73.3333Z"
        fill={fill}
      />
    </Icon>
  );
}

// eslint-disable-next-line import/no-default-export
export default FacebookIcon;
